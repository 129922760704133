.rut-formularioEmpresa{
    position: absolute; 
    margin-top: 3vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
.input-rut{
    position: absolute;
    width: 19.5vw;
    height: 2vw;
    margin-top: 1.5vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
.nombre-formularioEmpresa{
    position: absolute; 
    margin-top: 3vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
.input-nombreEmpresa{
    position: absolute;
    width: 19.5vw;
    height: 2vw;
    margin-top: 1.5vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
.industria-formularioEmpresa{
    position: absolute; 
    margin-top: 11vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
.input-industria{
    position: absolute;
    width: 20vw;
    height: 2.3vw;
    margin-top: 2.1vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
.region-formularioEmpresa{
    position: absolute; 
    margin-top: 11vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-regionEmpresa{
    position: absolute;
    width: 20vw;
    height: 2.3vw;
    margin-top: 1vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .anoFundacion-formularioEmpresa{
    position: absolute; 
    margin-top: 20vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-anoFundacion{
    position: absolute;
    width: 20vw;
    height: 2.3vw;
    margin-top: 1vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  /* formularioEmpresas2 */
  .ingresos-formularioEmpresa{
    position: absolute; 
    margin-top: 3vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-ingresos{
    position: absolute;
    width: 20vw;
    height: 2.3vw;
    margin-top: 1.5vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .empleados-formularioEmpresa{
    position: absolute; 
    margin-top: 3vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-empleados{
    position: absolute;
    width: 19.5vw;
    height: 2.3vw;
    margin-top: 1.5vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .valores-formularioEmpresa{
    position: absolute; 
    margin-top: 10vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-valores{
    position: absolute;
    width: 19.5vw;
    height: 2vw;
    margin-top: 1vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .esg-formularioEmpresa{
    position: absolute; 
    margin-top: 10vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-esg{
    position: absolute;
    width: 20vw;
    height: 2.3vw;
    margin-top: 3.1vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .impacto-formularioEmpresa{
    position: absolute; 
    margin-top: 19vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-impacto{
    position: absolute;
    width: 20vw;
    height: 2.3vw;
    margin-top: 1vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .empresaB-formularioEmpresa{
    position: absolute; 
    margin-top: 19vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-empresaB{
    position: absolute;
    width: 20vw;
    height: 2.3vw;
    margin-top: 2vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  /* formularioEmpresas3 */
  .contacto-formularioEmpresa{
    position: absolute; 
    margin-top: 3vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-contacto{
    position: absolute;
    width: 19.5vw;
    height: 2vw;
    margin-top: 1vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .cargo-formularioEmpresa{
    position: absolute; 
    margin-top: 3vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-cargo{
    position: absolute;
    width: 19.5vw;
    height: 2vw;
    margin-top: 1vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .mail-formularioEmpresa{
    position: absolute; 
    margin-top: 10vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-mail{
    position: absolute;
    width: 19.5vw;
    height: 2vw;
    margin-top: 1vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }

 