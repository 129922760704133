
.topnavbarempresas {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fa; 
    padding: 2px 10px; 
    z-index: 1000;
    height: 10vh;
    overflow: visible;
    gap:2px;
  }
  
  .topnavbarempresas > a {
    flex-grow: 1; 
    text-align: center; 
    margin: 0 4px;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    transition: color 0.3s ease;
    z-index: 1000;
    max-width: 20vh; 
}

.topnavbarempresas {
  width: 80vw; 
  margin: 0 auto; 
}
.logo {
    height: 40px;
    cursor: pointer;
    margin-right: 10px;
}

.topnavbarempresas > a {
    margin: 0 4px;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    transition: color 0.3s ease;
    z-index: 1000;
    margin-top: -3vh;
}
  
.topnavbarempresas > a:hover {
    color: white; 
}

.submenu {
    position: relative;
    z-index: 1000;
    margin-top:-1vh;
}
  
.submenu > a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin: 0 4px;
    cursor: pointer;
}
  
.submenu-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    z-index: 10000;
    min-width: 200px;
}
  
.submenu-content a {
    display: block;
    padding: 10px 10px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
    transition: background-color 0.3s ease;
}
  
.submenu-content a:hover {
    background-color: #f1f1f1;
    color: #333
}

.submenu:hover .submenu-content {
    display: block;
}

@media (max-width: 768px) {
  .topnavbarempresas {
    flex-direction: column;
    align-items: flex-start;
  }

  .submenu-content {
    position: static;
    box-shadow: none;
    border: none;
    min-width: 100%;
  }

  .submenu-content a {
    padding: 10px 20px;
  }
}
  