/*  INICIO Y COSAS COMUNES  */
.topnavbar1{
    background-color: #F9F9F9;
    overflow: hidden;
    position:sticky; 
    z-index: 100;
    width: 100vw;
  }
.topnavbar1 img{
    float: left;
    height: 4vw;
    width: 7vw;
    padding: 0.5vw 4vw;
  }
.WT1 {
    text-align: center;
    font-family: 'Montserrat-Medium';
  }
.WT-logo1 {
    height: 20vw;
    width: 30vw;
    background-color: white;
    border-color: white;
    z-index:1;
    border-radius: 10px;
    margin-top: 2vw;
    margin-left: 0vw;
  }
.rectangulo{
    position: absolute;
    width: 30vw;
    height: 52vw;
    background: #5A5A5A;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    z-index: 0;
    
}
.container{
    border: 1px solid gray;
    box-shadow: 1px 1px 3px gray;
    border-radius: 10px;
    flex-direction: column;
    margin-top: 8vw;
    margin-left: 20vw; 
    padding: 1vw;
    width: 60vw;
    height: 35vw;
    z-index: 100;
    position: absolute;
    background-color: white;
}
  .rojo{
    color: red;
  }
  .texto1{
    font-size: 1vw;
  }
  .texto2{
    color: gray; 
    font-size: 0.9vw;
  }
  .texto3{
    position: absolute;
    color: gray; 
    font-size: 0.9vw;
    margin-left: -15vw;
    width: 30vw;
    text-align: left;
    margin-top: 1vw;
  }
  .texto4{
    position: absolute;
    color: gray; 
    font-size: 0.9vw;
    margin-left: -15vw; 
    margin-top: 4vw;
  }
  .wot23{
    position: absolute;
    width: 55vw;
    height: 52vw;
    z-index: 0;
    margin-left: -5vw;
    
}
#empezar{
    width: 5vw;
    height: 2vw;
    background-color: #50B1C9;
    border-radius: 30px;
    padding-left: 5vw; /*con flecha 4 */
    padding-right: 5vw; /*con flecha 9 */ 
    padding-top: 1vw;
    padding-bottom: 0.5vw;
    color: white;
    text-decoration: none;
    margin-top: 6vw;
    position: absolute;
    margin-left: -8vw;
    text-align: center;

}
#empezar:hover {
    background: #E5007e;
    transition: 0.5s;
    color:white;
}
#siguiente{
    width: 13vw;
    height: 3.5vw;
    background-color: #50B1C9;
    border: solid; 
    border-radius: 10px;
    padding-left: 4vw; /*con flecha 4 */
    padding-right: 4vw; /*con flecha 9 */ 
    padding-top: 1vw;
    padding-bottom: 1vw;
    color: white;
    text-decoration: none;
    margin-top: 31.3vw;
    position: absolute;
    margin-left: 17vw;
    font-size: 1vw;

}
#siguiente:hover {
    background: #E5007e;
    transition: 0.5s;
    color:white;
}
#atras{
    width: 4.8vw;
    height: 1.3vw;
    background-color: #5A5A5A80;
    border-radius: 10px;
    padding-left: 4vw; /*con flecha 4 */
    padding-right: 4vw; /*con flecha 9 */ 
    padding-top: 1vw;
    padding-bottom: 1vw;
    color: white;
    text-decoration: none;
    margin-top: 31.4vw;
    position: absolute;
    margin-left: 3vw;
    font-size: 1vw;

}
#atras:hover {
    background: #E5007e;
    transition: 0.5s;
    color:white;
}
#siguiente2{
    width: 13vw;
    height: 3.5vw;
    background-color: #50B1C9;
    border: solid; 
    border-radius: 10px;
    padding-left: 4vw; /*con flecha 4 */
    padding-right: 4vw; /*con flecha 9 */ 
    padding-top: 1vw;
    padding-bottom: 1vw;
    color: white;
    text-decoration: none;
    margin-top: 30vw;
    position: absolute;
    margin-left: 17vw;
    font-size: 1vw;

}
#atras2{
    width: 4.8vw;
    height: 1.3vw;
    background-color: #5A5A5A80;
    border-radius: 10px;
    padding-left: 4vw; /*con flecha 4 */
    padding-right: 4vw; /*con flecha 9 */ 
    padding-top: 1vw;
    padding-bottom: 1vw;
    color: white;
    text-decoration: none;
    margin-top: 30.1vw;
    position: absolute;
    margin-left: 3vw;
    font-size: 1vw;

}
#siguiente2:hover {
    background: #E5007e;
    transition: 0.5s;
    color:white;
}
#atras2:hover {
    background: #E5007e;
    transition: 0.5s;
    color:white;
}
#siguiente3{
    width: 13vw;
    height: 3.5vw;
    background-color: #50B1C9;
    border: solid; 
    border-radius: 10px;
    padding-left: 4vw; /*con flecha 4 */
    padding-right: 4vw; /*con flecha 9 */ 
    padding-top: 1vw;
    padding-bottom: 1vw;
    color: white;
    text-decoration: none;
    margin-top: 31.3vw;
    position: absolute;
    margin-left: 17vw;
    font-size: 1vw;;

}
#atras3{
    width: 13vw;
    height: 3.5vw;
    background-color: #5A5A5A80;
    border: solid; 
    border-radius: 10px;
    padding-left: 4vw; /*con flecha 4 */
    padding-right: 4vw; /*con flecha 9 */ 
    padding-top: 1vw;
    padding-bottom: 1vw;
    color: white;
    text-decoration: none;
    margin-top: 30vw;
    position: absolute;
    margin-left: 3vw;
    font-size: 1vw;

}
#siguiente3:hover {
    background: #50B1C9;
    transition: 0.5s;
    color:white;
}
#atras3:hover {
    background: #E5007e;
    transition: 0.5s;
    color:white;
}
#atras4{
    width: 13vw;
    height: 3.5vw;
    background-color: #5A5A5A80;
    border: solid; 
    border-radius: 10px;
    padding-left: 4vw; /*con flecha 4 */
    padding-right: 4vw; /*con flecha 9 */ 
    padding-top: 1vw;
    padding-bottom: 1vw;
    color: white;
    text-decoration: none;
    margin-top: 31.3vw;
    position: absolute;
    margin-left: 3vw;
    font-size: 1vw;

}
#atras4:hover {
    background: #E5007e;
    transition: 0.5s;
    color:white;
}
progress[value] {
    width: 17vw;
    height: 0.6vw;
    position: absolute;
    margin-top: 46.3vw;
    margin-left: -17vw;
   
  
    /* Reinicializar estilos */
  
    appearance: none;
    -webkit-appearance: none;
  
  }
progress[value]::-webkit-progress-bar {
    background-color: #F5F5F5;
    border-radius: 10px;

  }
progress[value]::-webkit-progress-value {
    background-color: #E5007e;
    border-radius: 10px;
  }
.porcentaje{
    position: absolute;
    margin-top: 46vw;
    margin-left: 1vw;
    color: #323232;
    font-size: 0.9vw;
}
.numero1{
    position: absolute;
    border-radius: 100%;
    padding-left: 2.2vw;
    padding-right: 2.2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 2px solid #E5007e; 
    box-shadow: 1px 1px 1px #E5007e;
    z-index: 150;
    margin-left: -32.3vw; 
    background-color: white;
    margin-top: 10vw;
    font-size: 2vw;
    font-weight: bolder;
}
.numero1_1{
    position: absolute;
    border-radius: 100%;
    padding-left: 2.2vw;
    padding-right: 2.2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 2px solid white; 
    box-shadow: 1px 1px 1px gray;
    z-index: 150;
    margin-left: -32.3vw; 
    background-color: white;
    margin-top: 10vw;
    font-size: 2vw;
    font-weight: bolder;
    color: gray;
}
.numero2{
    position: absolute;
    border-radius: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 2px solid white; 
    box-shadow:  1px 1px 1px gray;;
    z-index: 150;
    margin-left: -32.3vw; 
    background-color: white;
    margin-top: 17vw;
    font-size: 2vw;
    font-weight: bolder;
    color: gray;
    
}
.numero2_1{
    position: absolute;
    border-radius: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 2px solid #E5007e; 
    box-shadow: 1px 1px 1px #E5007e;
    z-index: 150;
    margin-left: -32.3vw; 
    background-color: white;
    margin-top: 17vw;
    font-size: 2vw;
    font-weight: bolder;
    
    
}
.numero3{
    position: absolute;
    border-radius: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 2px solid white; 
    box-shadow:  1px 1px 1px gray;;
    z-index: 150;
    margin-left: -32.3vw; 
    background-color: white;
    margin-top: 24vw;
    font-size: 2vw;
    font-weight: bolder;
    color: gray;
    
}
.numero3_1{
    position: absolute;
    border-radius: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 2px solid #E5007e; 
    box-shadow: 1px 1px 1px #E5007e;
    z-index: 150;
    margin-left: -32.3vw; 
    background-color: white;
    margin-top: 24vw;
    font-size: 2vw;
    font-weight: bolder;
    
}
.numero4{
    position: absolute;
    border-radius: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 2px solid white; 
    box-shadow:  1px 1px 1px gray;;
    z-index: 150;
    margin-left: -32.3vw; 
    background-color: white;
    margin-top: 31vw;
    font-size: 2vw;
    font-weight: bolder;
    color: gray;
    
}
.numero4_1{
    position: absolute;
    border-radius: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 2px solid #E5007e; 
    box-shadow: 1px 1px 1px #E5007e;
    z-index: 150;
    margin-left: -32.3vw; 
    background-color: white;
    margin-top: 31vw;
    font-size: 2vw;
    font-weight: bolder;
    
}
.numero5{
    position: absolute;
    border-radius: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 2px solid white; 
    box-shadow:  1px 1px 1px gray;;
    z-index: 150;
    margin-left: -32.3vw; 
    background-color: white;
    margin-top: 38vw;
    font-size: 2vw;
    font-weight: bolder;
    color: gray;
    
}
.numero5_1{
    position: absolute;
    border-radius: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 2px solid #E5007e; 
    box-shadow: 1px 1px 1px #E5007e;
    z-index: 150;
    margin-left: -32.3vw; 
    background-color: white;
    margin-top: 38vw;
    font-size: 2vw;
    font-weight: bolder;
    
}
.infopersonal{
    position: absolute;
    width: 10vw;
    margin-top: 11vw;
    z-index: 150; 
    margin-left: -42vw;
    color: white; 
    font-weight: bolder;
    font-size: 1vw;
}
.infopersonal2{
    position: absolute;
    width: 10vw;
    margin-top: 11vw;
    z-index: 150; 
    margin-left: -42vw;
    color: white; 
    font-weight: lighter;
    font-size: 1vw;
    
}
.infoprofesional{
    position: absolute;
    width: 10vw;
    margin-top: 18vw;
    z-index: 150; 
    margin-left: -42vw;
    color: white;
    font-weight: lighter;
    font-size: 1vw;
}
.infoprofesional2{
    position: absolute;
    width: 10vw;
    margin-top: 18vw;
    z-index: 150; 
    margin-left: -42vw;
    color: white;
    font-weight: bolder;
    font-size: 1vw;
}
.disponibilidad{
    position: absolute;
    width: 10vw;
    margin-top: 26vw;
    z-index: 150; 
    margin-left: -42vw;
    color: white;
    font-weight: lighter;
    font-size: 1vw;
}
.disponibilidad4{
    position: absolute;
    width: 10vw;
    margin-top: 26vw;
    z-index: 150; 
    margin-left: -42vw;
    color: white;
    font-weight: bolder;
    font-size: 1vw;
}
.infoadicional{
    position: absolute;
    width: 10vw;
    margin-top: 32vw;
    z-index: 150; 
    margin-left: -42vw;
    color: white;
    font-weight: lighter;
    font-size: 1vw;
}
.infoadicional2{
    position: absolute;
    width: 10vw;
    margin-top: 32vw;
    z-index: 150; 
    margin-left: -42vw;
    color: white;
    font-weight: bolder;
    font-size: 1vw;

}
.referencias{
    position: absolute;
    width: 10vw;
    margin-top: 40vw;
    z-index: 150; 
    margin-left: -42vw;
    color: white;
    font-weight: lighter;
    font-size: 1vw;
}
.referencias2{
    position: absolute;
    width: 10vw;
    margin-top: 40vw;
    z-index: 150; 
    margin-left: -42vw;
    color: white;
    font-weight: bolder;
    font-size: 1vw;
}
/* USUARIAS 1 */
.nombre{
    position: absolute; 
    margin-top: 2vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.ejemplo{
    font-size: 0.75vw;
    color:#5A5A5A;
}
.input1{
    border-radius: 5px;
    width: 20vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
    border: 1px solid #D9D9D9;
    margin-top: 0.5vw;
}
.apellido{
    position: absolute; 
    margin-top: 2vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    

}
.rut{
    position: absolute; 
    margin-top: 9vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    

}
.celular{
    position: absolute; 
    margin-top: 9vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    

}
.mail{
    position: absolute; 
    margin-top: 16vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.intereses{
    position: absolute; 
    margin-top: 23vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.paisdom{
    position: absolute; 
    margin-top: 16vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
.input3{
    border-radius: 5px;
    width: 20.5vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
    border: 1px solid #D9D9D9;
    margin-top: 1.5vw;
    font-size: 1vw;
}
.pais{
    position: absolute; 
    margin-top: 2vw; 
    margin-left: 0vw;
    text-align: left;
    font-size: 0.85vw; 
}
/* Usuarias 2 */
.active{
    color:#E5007e; 
    font-weight: bolder;
    padding-left: 7vw;
    padding-right: 7vw;
    font-size: 1.1vw;
    background-color: transparent;
    border: transparent;
    text-decoration: underline;

}
.topwords{
    color:#E5007e; 
    text-decoration: none;
    padding-left: 7vw;
    padding-right: 7vw;
    background-color: transparent;
    border: transparent;
}
.input4{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
    border: 1px solid #D9D9D9;
    margin-left: -2.9vw;
    font-size: 1vw;

}
.input5{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
    border: 1px solid #D9D9D9;
    margin-left: -2.9vw;
    margin-top : 1.5vw;
    font-size: 1vw;
}
.input6{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
    border: 1px solid #D9D9D9;
    margin-left: -21.8vw;
    margin-top: 2vw;
    font-size: 1vw;
}
.input7{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
    border: 1px solid #D9D9D9;
    margin-left: -0.5vw;
    margin-top : 4.5vw;
    font-size: 1vw;

}
.input8{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
    border: 1px solid #D9D9D9;
    margin-left: -0.5vw;
    margin-top : 1vw;
    font-size: 1vw;

}
.ejemplo1{
    font-size: 0.75vw;
    color:#5A5A5A;
    width:20vw;
    text-align: justify;
    position: absolute;
}
.ejemplo3{
    font-size: 0.75vw;
    color:#5A5A5A;
    width: 20vw;
    text-align: justify;
    position: absolute;
}
.universidad2{
    position: absolute; 
    margin-top: 9vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
.postgrado{
    position: absolute; 
    margin-top: 16vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
/* USUARIAS3 */
.cargo-actual{
    position: absolute; 
    margin-top: 2vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input10{
    border-radius: 5px;
    width: 20vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 1px solid #D9D9D9;
    margin-top: 3vw;
    font-size: 1vw;
}
.ejemplo4{
    font-size: 0.75vw;
    color:#5A5A5A;
    width: 20vw;
    position: absolute;
}
.empresa-actual{
    position: absolute; 
    margin-top: 2vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
.input11{
    border-radius: 5px;
    width: 20vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 1px solid #D9D9D9;
    margin-top: 2vw;
    font-size: 1vw;
}
.industria-actual{
    position: absolute; 
    margin-top: 11vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.ejemplo5{
    font-size: 0.75vw;
    color:#5A5A5A;
    width: 20vw;
    position: absolute;
}
.input12{
    border-radius: 5px;
    width: 20vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 1px solid #D9D9D9;
    margin-top: 3vw;
    font-size: 1vw;
}
.otro-rol{
    position: absolute; 
    margin-top: 11vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
}
.input13{
    border-radius: 5px;
    width: 20.4vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 1px solid #D9D9D9;
    margin-top: 2vw;
    font-size: 1vw;
}
.cargo-adicional{     
    position: absolute; 
    margin-top: 20vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input14{
    border-radius: 5px;
    width: 20vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    font-size: 1vw;
}
.empresa-adicional{
    position: absolute; 
    margin-top: 20vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
.input15{
    border-radius: 5px;
    width: 20vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    font-size: 1vw;
}
.industria-adicional{
    position: absolute; 
    margin-top: 27vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input16{
    border-radius: 5px;
    width: 20vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    font-size: 1vw;
}
/* USUARIAS4 */

.anos{ 
    position: absolute; 
    margin-top: 2vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input17{
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    padding-top: 0.7vw;
    padding-bottom: 0.7vw;
    font-size: 1vw;
}
.areas{
    position: absolute; 
    margin-top: 19vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input18{
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 5vw;
    font-size: 1vw;
}
.ejemplo6{
    font-size: 0.75vw;
    color:#5A5A5A;
    width: 20vw;
    position: absolute;
    margin-top: 0.4vw;
}
.ejemplo7{
    font-size: 0.75vw;
    color:#5A5A5A;
    width: 20vw;
    position: absolute;
    margin-top: 1.5vw;
}
.industria{
    position: absolute; 
    margin-top: 2vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    
}
.input19{
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 0.5vw;   
    font-size: 1vw;
}
.competencias{
    position: absolute; 
    margin-top: 15vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    
}
.input20{
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 3vw;
    font-size: 1vw;
}
.ejemplo8{
    font-size: 0.75vw;
    color:#5A5A5A;
    width: 20vw;
    position: absolute;
    margin-top: 0.5vw;
}
.experiencia{
    position: absolute; 
    margin-top: 8vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input-experiencia{
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 0.5vw;
    padding-top: 0.7vw;
    padding-bottom: 0.7vw;
    font-size: 1vw;
    
}
.formacion{
    position: absolute; 
    margin-top: 14vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.espacio{
    margin-top: 0.5vw;
}
/* USUARIAS5 */
.disponibilidad2{
    position: absolute; 
    margin-top: 3vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input21{
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 3vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1vw;

}
.cambio-region{
    position: absolute; 
    margin-top: 3vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
.jornada{
    position: absolute; 
    margin-top: 13vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input22{
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1vw;
    margin-top: 1vw;

}
.modalidad{
    position: absolute; 
    margin-top: 9vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
.input23{
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1vw;
}
.viaje{
    position: absolute; 
    margin-top: 16vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
/* USUARIAS6  */
.idioma{
    position: absolute; 
    margin-top: 2vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.idioma2{
    position: absolute; 
    margin-top: 2vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
.input24{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    padding-top: 0.7vw;
    padding-bottom: 0.7vw;
    font-size: 1vw;
}
.input34{
    position: absolute;
    font-size: 1vw;
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    padding-top: 0.7vw;
    padding-bottom: 0.7vw;

}
.factor{
    position: absolute; 
    margin-top: 8vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.factor2{
    position: absolute; 
    margin-top: 9vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
.input26{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    padding-top: 0.7vw;
    padding-bottom: 0.7vw;
    font-size: 1vw;
}
.region1{
    position: absolute; 
    margin-top: 13.5vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    width: 30vw;
}
.espacio2{
    margin-top: 1vw;
}
.region2{
    position: absolute; 
    margin-top: 15vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
.input27{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 2vw;
    margin-left: -12vw;
    padding-top: 0.7vw;
    padding-bottom: 0.7vw;
    font-size: 1vw;
}
.pueblo{
    position: absolute; 
    margin-top: 20vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input28{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 2vw;
    margin-left: -16.5vw;
    padding-top: 0.7vw;
    padding-bottom: 0.7vw;
    font-size: 1vw;
}
.pueblo2{
    position: absolute; 
    margin-top: 21vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
.conocer-wot{
    position: absolute; 
    margin-top: 26vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input25{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    padding-top: 0.7vw;
    padding-bottom: 0.7vw;
    font-size: 1vw;
}
/* USUARIAS7 */
.input29{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1vw;
}
.linkedin{
    position: absolute; 
    margin-top: 2vw;; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.personalidad{
    position: absolute; 
    margin-top: 2vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;

}
.input30{
    position: absolute;
    border-radius: 5px;
    width: 50vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    padding-top: 4vw;
    padding-bottom: 4vw;
    font-size: 1vw;

}
.brief{
    position: absolute; 
    margin-top: 11vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.cv{
    position: absolute; 
    margin-top: 25vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
/* USUARIAS8, 9, 10 */
.validar{
    position: absolute; 
    margin-top: 3vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.contacto{
    position: absolute; 
    margin-top: 9vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input31{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}
.celular-contacto{
    position: absolute; 
    margin-top: 16vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
}
.input32{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}
.mail-contacto{
    position: absolute; 
    margin-top: 9vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
}
.input33{
    position: absolute;
    border-radius: 5px;
    width: 20vw;
    border: 1px solid #D9D9D9;
    margin-top: 1vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}
/* USUARIAS11 */
.enviar{
    position: absolute;
    margin-left:25vw;
    font-size: 1vw;
    margin-top: 2vw;
}
.declaracion{
    position: absolute;
    margin-left: 10vw;
    margin-top: 7vw;
    font-size: 0.85vw;
}
.terminos{
    position: absolute;
    margin-left: 10vw;
    margin-top: 9vw;
    font-size: 0.85vw;
}
.terminos2{
    position: absolute;
    margin-left: -8vw;
    font-size: 0.85vw;
    color: #E5007e;
}
.politicas2{
    position: absolute;
    margin-left: 7vw;
    font-size: 0.85vw;
    color: #E5007e;
}
.terminos-politicas{
    position: absolute;
    margin-left: -5vw;
    margin-top: 12vw;
    font-size: 0.85vw;
    width: 50vw;
  }
/* USUARIAS12 */
.texto5{
    position: absolute;
    font-size: 0.9vw;
    margin-left: -15.5vw; 
    margin-top: 1vw;
}
.texto6{
    position: absolute;
    font-size: 0.9vw;
    margin-left: -15.5vw; 
    margin-top: 2.5vw;
}
.texto7{
    position: absolute;
    font-size: 1vw;
    margin-left: -10vw;; 
    margin-top: 5vw;
    font-weight: bolder;
    color:#E5007e;
}

.WT-logo2 {
    height: 20vw;
    width: 30vw;
    background-color: white;
    border-color: white;
    z-index:1;
    border-radius: 10px;
    margin-top: 2vw;
  }

/* CELULARES */
@media screen and (max-width: 1024px) and (orientation: portrait) {
    /*COSAS COMUNES */
    .rectangulo{
        display: none;
    }
    .wot23{
        display: none; 
    }
    .container{
        margin-top: 8vw;
        height: 150vw;
        width: 80vw;
        margin-left: 10vw;
        background-color: #F9F9F9;
        box-shadow: 5px 5px 5px gray;
    }
    .topnavbar1 img{
        height: 10vw;
        width: 10vw;
    }
    .numero1{
        margin-left: -47vw; 
        margin-top: 25vw;
        font-size: 7vw;
        height: 10vw;
        width: 9vw;
    }
    .numero1_1{
        margin-left: -47vw; 
        margin-top: 25vw;
        font-size: 7vw;
        height: 10vw;
        width: 9vw;
    }
    .numero2{
        margin-left: -47vw; 
        margin-top: 50vw;
        font-size: 7vw;
        height: 10vw;
        width: 9vw;
    }
    .numero2_1{
        margin-left: -47vw; 
        margin-top: 50vw;
        font-size: 7vw;
        height: 10vw;
        width: 9vw;
    }
    .numero3{
        margin-left: -47vw; 
        margin-top: 75vw;
        font-size: 7vw;
        height: 10vw;
        width: 9vw;
    }
    .numero3_1{
        margin-left: -47vw; 
        margin-top: 75vw;
        font-size: 7vw;
        height: 10vw;
        width: 9vw;  
    }
    .numero4{
        margin-left: -47vw; 
        margin-top: 100vw;
        font-size: 7vw;
        height: 10vw;
        width: 9vw;
        
    }
    .numero4_1{
        margin-left: -47vw; 
        margin-top: 100vw;
        font-size: 7vw;
        height: 10vw;
        width: 9vw;
        
    }
    .numero5{
        margin-left: -47vw; 
        margin-top: 125vw;
        font-size: 7vw;
        height: 10vw;
        width: 9vw; 
    }
    .numero5_1{
        margin-left: -47vw; 
        margin-top: 125vw;
        font-size: 7vw;
        height: 10vw;
        width: 9vw;  
    }
    .infopersonal{
        display: none;
    }
    .infopersonal2{
        display: none;
    }
    .infoprofesional{
        display: none;
    }
    .infoprofesional2{
        display: none;
    }
    .disponibilidad{
        display: none;
    }
    .disponibilidad4{
        display: none;
    }
    .infoadicional{
        display: none;
    }
    .infoadicional2{
        display: none;
    }
    .referencias{
        display: none;
    }
    .referencias2{
        display: none;
    }
    /* INICIO */
    .WT-logo1 {
        height: 45vw;
        width: 65vw;
        background-color: white;
        border-color: white;
        z-index:1;
        border-radius: 10px;
        margin-top: 35vw;
        margin-left: 0vw;
    }
    .texto1{
        position: absolute;
        font-size: 5vw;
        margin-top: 10vw;
        width: 100vw;
        margin-left: -50vw;
    }
    .texto2{ 
        font-size: 3vw;
        position: absolute;
        margin-top: 25vw;
        width: 100vw;
        margin-left: -50vw;
    }
    .texto3{
        font-size: 2vw;
        margin-left: -30vw;
        width: 60vw;
        margin-top: 3vw;
    }
    .texto4{ 
        font-size: 2vw;
        margin-left: -30vw; 
        margin-top: 10vw;
    }
    #empezar{
        width: 25vw;
        height: 7vw;
        border-radius: 15px;
        margin-top: 20vw;
        margin-left: -18vw;
    }
    /* USUARIAS1 */
    .nombre{
        margin-top: 8vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .ejemplo{
        font-size: 1.5vw;
    }
    .input1{
        border-radius: 5px;
        width: 50vw;
        margin-top: 1.5vw;
    }
    .apellido{
        position: absolute; 
        margin-top: 24vw; 
        margin-left: 17vw;
        text-align: left;
        font-size: 2.5vw;
    }
    .rut{
        position: absolute; 
        margin-top: 40vw; 
        margin-left: 17vw;
        text-align: left;
        font-size: 2.5vw;
    }
    .celular{
        position: absolute; 
        margin-top: 57vw; 
        margin-left: 17vw;
        text-align: left;
        font-size: 2.5vw;
    }
    .mail{
        position: absolute; 
        margin-top: 76vw; 
        margin-left: 17vw;
        text-align: left;
        font-size: 2.5vw;
    }
    .intereses{
        position: absolute; 
        margin-top: 94vw; 
        margin-left: 17vw;
        text-align: left;
        font-size: 2.5vw;
    }
    .paisdom{
        position: absolute; 
        margin-top: 114vw; 
        margin-left: 17vw;
        text-align: left;
        font-size: 2.5vw;
    }
    .input3{
        width: 50vw;
        padding-top: 2vw;
        padding-bottom: 2vw;
        
    }
    .pais{
        position: absolute; 
        margin-top: 1vw; 
        margin-left: 0vw;
        text-align: left;
        font-size: 2.5vw; 
    }
    #siguiente{
        width: 15vw;
        height: 6vw;
        margin-top: 142vw;
        position: absolute;
        margin-left: 17vw;
        font-size: 1.5vw;
    
    }
    #atras{
        width: 6.8vw;
        height: 3.2vw;
        margin-top: 142.2vw;
        position: absolute;
        margin-left: 0vw;
        font-size: 1.5vw;
        padding-top: 2vw;
        padding-bottom: 0.5vw;
    }
    /* USUARIAS2 */
    .active{
        display: none;
    }
    .topwords{
        display: none;
    }
    .input4{
        width: 50vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        margin-left: -11vw;
        font-size: 1vw;
    
    }
    .input5{
        width: 50vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        margin-left: -11vw;
        margin-top : 4vw;
        font-size: 1vw;
    }
    .input6{
        width: 48vw;
        padding-top: 1.6vw;
        padding-bottom: 1.6vw;
        margin-left: -42vw;
        margin-top: 5vw;
        font-size: 1vw;
    }
    .input7{
        width: 50vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        margin-top : 8vw;
        font-size: 1vw;
    }
    .input8{
        width: 50vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        margin-top : 2vw;
        font-size: 1vw;
    }
    .ejemplo1{
        font-size: 1.5vw;
        color:#5A5A5A;
        width: 50vw;
        text-align: justify;
        position: absolute;
    }
    .ejemplo3{
        font-size: 1.5vw;
        color:#5A5A5A;
        width: 50vw;
        text-align: justify;
        position: absolute;
    }
    .universidad2 {
        position: absolute; 
        margin-top: 59vw; 
        margin-left: 17vw;
        text-align: left;
        font-size: 2.5vw;
    }
    .postgrado{
        position: absolute; 
        margin-top: 97vw; 
        margin-left: 17vw;
        text-align: left;
        font-size: 2.5vw;
    }
    #siguiente2{
        width: 17vw;
        height: 6vw;
        margin-top: 140vw;
        position: absolute;
        margin-left: 17vw;
        font-size: 2vw;
    
    }
    #atras2{
        width: 8.2vw;
        height: 3.2vw;
        margin-top: 140.5vw;
        position: absolute;
        margin-left: -2vw;
        font-size: 2vw;
    
    }
    /* USUARIAS3 */
    .cargo-actual{
        margin-top: 8vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input10{
        width: 50vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        margin-top: 6vw;
        font-size: 1vw;
    }
    .ejemplo4{
        font-size: 1.5vw;
        width: 50vw;
        margin-top: 1vw;
    }
    .empresa-actual{
        margin-top: 25vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input11{
        width: 48vw;
        padding-top: 1.7vw;
        padding-bottom: 1.7vw;
        margin-top: 2vw;
    }
    .industria-actual{
        margin-top: 42vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .ejemplo5{
        font-size: 1.5vw;
        width: 50vw;
    }
    .input12{
        width: 50vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        margin-top: 6vw;
    }
    .otro-rol{
        margin-top: 60vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
        width: 50vw;
    }
    .input13{
        width: 50vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    }
    .cargo-adicional{     
        margin-top: 78vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input14{
        width: 50vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        margin-top: 3vw;
    }
    .empresa-adicional{
        margin-top: 94vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input15{
        width: 48vw;
        padding-top: 1.7vw;
        padding-bottom: 1.7vw;
        margin-top: 3vw;
    }
    .industria-adicional{
        margin-top: 110vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input16{
        width: 50vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        margin-top: 3vw;
    }
    #atras3{
        width: 17vw;
        height: 6vw;
        margin-top: 140vw;
        position: absolute;
        margin-left: -2vw;
        font-size: 2vw;
    
    }
    /* Usuarias4 VER DESPUES */
    .anos{ 
        margin-top: 7vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input17{
        width: 50vw;
        margin-top: 1vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    }
    .areas{
        margin-top: 40vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input18{
        width: 50vw;
        margin-top: 7.5vw;
    }
    .ejemplo6{
        font-size: 1.5vw;
        width: 50vw;
        position: absolute;
        margin-top: 0.4vw;
    }
    .ejemplo7{
        font-size: 1.5vw;
        width: 50vw;
        position: absolute;
        margin-top: 3vw;
    }
    .industria{
        position: absolute; 
        margin-top: 76vw; 
        margin-left: 17vw;
        text-align: left;
        font-size: 2.5vw;
        
    }
    .input19{
        width: 50vw;
        margin-top: 0.5vw;   
        font-size: 1vw;
    }
    .competencias{
        position: absolute; 
        margin-top: 107vw; 
        margin-left: 17vw;
        text-align: left;
        font-size: 2.5vw;
        
    }
    .input20{
        width: 50vw;
        margin-top: 5vw;
    }
    .ejemplo8{
        font-size: 1.5vw;
        width: 50vw;
        margin-top: 0.5vw;
    }
    .experiencia{
        margin-top: 18vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .formacion{
        margin-top: 30vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .espacio{
        margin-top: 0.5vw;
    }
    /* USUARIAS 5 */
    .disponibilidad2{
        margin-top: 15vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input21{
        width: 50vw;
        margin-top: 6vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;    
    }
    .cambio-region{
        margin-top:33vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .jornada{
        margin-top: 45vw; 
        margin-left: 17vw;
        text-align: left;
        font-size: 2.5vw;
    }
    .input22{
        width: 50vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        margin-top: 2vw;
    
    }
    .modalidad{
        margin-top: 58vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input23{
        width: 50vw;
        margin-top: 2vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    }
    .viaje{
        margin-top: 72vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    /* USUARIAS6 */
    .idioma{
        margin-top: 10vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .idioma2{
        margin-top: 23vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input24{
        width: 50vw;
        margin-top: 2vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    }
    .input34{
        width: 48vw;
        margin-top: 2vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    
    }
    .factor{
        margin-top: 35vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .factor2{
        margin-top: 49vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input26{
        width: 48vw;
        margin-top: 2vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    }
    .region1{
        margin-top: 61vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
        width: 50vw;
        text-align: justify;
    }
    .espacio2{
        margin-top: 1.5vw;
    }
    .region2{
        margin-top: 80vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input27{
        width: 50vw;
        margin-top: 4vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        margin-left: -36vw;
    }
    .pueblo{
        margin-top: 93vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input28{
        width: 48vw;
        margin-top: 5vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        margin-left: -49vw;
    }
    .pueblo2{
        margin-top: 105vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .conocer-wot{
        margin-top: 118vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input25{
        width: 50vw;
        margin-top: 3vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    }
    /* USUARIAS7 */
    .input29{
        width: 48vw;
        margin-top: 2vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    }
    .linkedin{
        margin-top: 15vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .personalidad{
        margin-top: 32vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    
    }
    .input30{
        position: absolute;
        width: 48vw;
        margin-top: 2vw;
        padding-top: 4vw;
        padding-bottom: 4vw;
    
    }
    .brief{
        margin-top: 48vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .cv{
        margin-top: 69vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    #cv{
        width: 50vw;
        margin-top: 2vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        font-size: 2vw;
    }
    /* USUARIAS 8,9,10 */
    .validar{
        margin-top: 15vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
        width: 50vw;
    }
    .contacto{
        margin-top: 32vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
    }
    .input31{
        width: 50vw;
        margin-top: 2vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    }
    .celular-contacto{
        margin-top: 48vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
        width: 50vw;
    }
    .input32{
        width: 50vw;
        margin-top: 2vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    }
    .mail-contacto{
        margin-top: 65vw; 
        margin-left: 17vw;
        font-size: 2.5vw;
        width: 50vw;
    }
    .input33{
        width: 50vw;
        margin-top: 2vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
    }
    /* USUARIAS11 */
    .enviar{
        margin-left:20vw;
        font-size: 4vw;
        margin-top : 10vw;
    }
    #radio1{
        position: absolute;
        margin-left: 25vw;
        margin-top: 1vw;
        font-size: 2vw;
    }
    .seguir{
        position: absolute;
        margin-left: 8vw;
        margin-top: 23vw;
        font-size: 2vw;
        width: 70vw;
    }
    .declaracion{
        margin-left: 10vw;
        margin-top: 35vw;
        font-size: 2vw;
    }
    .terminos{
        position: absolute;
        margin-left: 10vw;
        margin-top: 40vw;
        font-size: 2vw;
    }
    .terminos2{
        position: absolute;
        margin-left: -15vw;
        font-size: 2vw;
        color: #E5007e;
    }
    .politicas2{
        position: absolute;
        margin-left: 16vw;
        font-size: 2vw;
        color: #E5007e;
    }
    .terminos-politicas{
        position: absolute;
        margin-left: -20vw;
        margin-top: 50vw;
        font-size: 1.5vw;
        width: 90vw;
    }
/* USUARIAS12 */
    .texto5{
        position: absolute;
        font-size: 2vw;
        margin-left: -27vw; 
        margin-top: 1vw;
    }
    .texto6{
        position: absolute;
        font-size: 2vw;
        margin-left: -27vw; 
        margin-top: 4vw;
        width: 60vw;
        text-align: left;
    }
    .texto7{
        position: absolute;
        font-size: 2.5vw;
        margin-left: -22vw;; 
        margin-top: 10vw;
        font-weight: bolder;
        color:#E5007e;
    }

    .WT-logo2 {
        height: 45vw;
        width: 65vw;
        background-color: white;
        border-color: white;
        z-index:1;
        border-radius: 10px;
        margin-top: 20vw;
        margin-left: 0vw;
    }
    
}

