.tabla-usuarias{
  width: 50vw;
  font-size: 1vw;
  margin-top: 7vw;
  margin-left: 3vw;
  position: absolute;
}
.boton-mas{
  position:absolute; 
  margin-top: 28vw;
  margin-left: 16vw;
  width: 8vw;
  height: 3vw;
  border-radius: 1vw;
  background-color: #F2F2F2;
  border: 1px solid #C4C4C4;
}

.boton-menos{
  position:absolute; 
  margin-top: 28vw;
  margin-left: 7vw;
  width: 8vw;
  height: 3vw;
  border-radius: 1vw;
  background-color: #F2F2F2;
  border: 1px solid #C4C4C4;
}

.busqueda{
  position:absolute; 
  margin-top: 4vw;
  margin-left: -16vw;
  width: 20vw;
  height: 2vw;
  border-radius: 0.5vw;
  background-color: #F2F2F2;
  border: 1px solid #C4C4C4;
}

.boton-busqueda{
  position:absolute; 
  margin-top: 4.1vw;
  margin-left: 5vw;
  width: 6vw;
  height: 2vw;
  border-radius: 0.5vw;
  background-color: #50B1C9;
  border: 1px solid #C4C4C4;
}

.boton-busqueda:hover{
  background-color: #E5007e;
}
.cont-busqueda{
  margin-top: 4vw;
  width: 20vw;
  height: 2vw;

}
.nombre-busqueda{
  position:absolute; 
  margin-left: 0vw;
  height: 2vw;
  font-size: 1vw;
}

.boton-busquedas{
  margin-left: 40vw;
  width: 17vw;
  height: 2vw;
  border-radius: 0.5vw;
  background-color: #50B1C9;

}
.boton-busquedas:hover{
  background-color: #E5007e;
}

.textoBusuqeda{
  position: absolute;
  margin-top: 5vw;
  margin-left: 40vw;
  width: 6vw;
  height: 2vw;
  font-size: 2vw;
  color: #50B1C9;
  font-weight: bolder;
}


/* pARTE PONERADORES  */

.ponderadores-container {
  border: 1px solid gray;
  box-shadow: 1px 1px 3px gray;
  border-radius: 10px;
  margin-top: 2vw;
  margin-left: 5vw;
  padding: 1vw;
  width: 87vw;
  max-height: 35vw; 
  overflow: auto;
  z-index: 100;
  position: absolute;
  background-color: white;
  font-size: 1vw;
  color: #323232;
}

.ponderadores-table {
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
}

.ponderadores-table th, 
.ponderadores-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}

.header{
  font-size: 0.8vw;
  writing-mode: horizontal-tb;
  text-align: left;
  position: sticky;
  left: 0;
  top: 10px;
  background-color: #c4c4c4;
  }

.ponderacion-input {
  font-size: 0.8vw;
  width: 35px;
  height: 15px;
  padding: 5px;
}
/* Hacer para mi update-message que quede fijo el mensaje pero tiene que estar dentro del container   */

.update-message {
  position: sticky;
  top: -15px;
  left: 0;
  background-color: #f0f0f0; /* Color de fondo del mensaje */
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra para resaltar el mensaje */
  z-index: 1; /* Asegura que el mensaje esté sobre otros elementos */
}

.funcion-actual {
  position: sticky;
  font-size: 16px;
  font-weight: bold;
  color: #333; /* Color de texto */
  background-color: #f0f0f0; /* Color de fondo */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra para resaltar */
  margin-bottom: 20px; /* Espacio inferior para separar del contenido siguiente */
}
