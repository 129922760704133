/* COMUN */
.topnavbar-busquedas{
    background-color: #F9F9F9;
    overflow: hidden;
    position:sticky; 
    z-index: 100;
    width: 100vw;
  }
.topnavbar-busquedas img{
    float: left;
    height: 4vw;
    width: 7vw;
    margin-left: 0vw;
    padding: 0.5vw 4vw;
  }
  .topnavbar-busquedas a{
    float: right;
    color: rgb(73, 73, 73);
    text-align: center;
    padding: 2% 2%;
    text-decoration: none;
    font-size: 1.2vw;
    font-weight:bolder;
  } 
  .topnavbar-busquedas a:hover {
    background-color: #E5007e;
    color: white;
  }
  .topnavbar-busquedas a.active {
    background-color: #f9dbbd;
    color: white;
  }
.WT-busquedas {
    text-align: center;
    font-family: 'Montserrat-Medium';
  }
.WT-logo-busquedas {
    height: 20vw;
    width: 30vw;
    background-color: white;
    border-color: white;
    z-index:1;
    border-radius: 10px;
    margin-top: 4vw;
    margin-left: 0vw;
  }
.rectangulo-busquedas{
    position: absolute;
    width: 30vw;
    height: 52vw;
    background: #5A5A5A;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    z-index: 0;
    
}
.container-busquedas{
    border: 1px solid gray;
    box-shadow: 1px 1px 3px gray;
    border-radius: 10px;
    flex-direction: column;
    margin-top: 8vw;
    margin-left: 20vw; 
    padding: 1vw;
    width: 60vw;
    height: 35vw;
    z-index: 100;
    position: absolute;
    background-color: white;
}
.rojo{
    color: red;
}
.texto1-busquedas{
    position: absolute;
    margin-top: 0.7vw;
    margin-left: -10vw;
    font-size: 1vw;
  }
.texto3-busquedas{
    position: absolute;
    color: gray; 
    font-size: 0.9vw;
    margin-left: -15vw;
    width: 30vw;
    text-align: left;
    margin-top: 1vw;
}
.texto4-busquedas{
    position: absolute;
    color: gray; 
    font-size: 0.9vw;
    margin-left: -15vw; 
    margin-top: 4vw;
  }
.wot23-busquedas{
    position: absolute;
    width: 55vw;
    height: 52vw;
    z-index: 0;
    margin-left: -5vw;
    
}
#empezar-busquedas{
  width: 13vw;
  height: 3.5vw;
  background-color: #E5007e;
  border: solid; 
  border-radius: 10px;
  padding-left: 4vw; /*con flecha 4 */
  padding-right: 4vw; /*con flecha 9 */ 
  padding-top: 1vw;
  padding-bottom: 1vw;
  color: white;
  text-decoration: none;
  margin-top: 7vw;
  position: absolute;
  margin-left: -7vw;
  font-size: 1vw;

}
#empezar-busquedas:hover {
    background: #50B1C9;
    transition: 0.5s;
    color:white;
}
#atras-busquedas{
  width: 13vw;
  height: 3.5vw;
  background-color: #5A5A5A80;
  border: solid; 
  border-radius: 10px;
  padding-left: 4vw; /*con flecha 4 */
  padding-right: 4vw; /*con flecha 9 */ 
  padding-top: 1vw;
  padding-bottom: 1vw;
  color: white;
  text-decoration: none;
  margin-top: 31.3vw;
  position: absolute;
  margin-left: 3vw;
  font-size: 1vw;

}
#atras-busquedas:hover {
  background: #E5007e;
  transition: 0.5s;
  color:white;
}
#siguiente-busquedas{
  width: 13vw;
  height: 3.5vw;
  background-color: #E5007e;
  border: solid; 
  border-radius: 10px;
  padding-left: 4vw; /*con flecha 4 */
  padding-right: 4vw; /*con flecha 9 */ 
  padding-top: 1vw;
  padding-bottom: 1vw;
  color: white;
  text-decoration: none;
  margin-top: 31.3vw;
  position: absolute;
  margin-left: 17vw;
  font-size: 1vw;

}
#siguiente-busquedas:hover {
  background: #50B1C9;
  transition: 0.5s;
  color:white;
}
.numero1-busquedas{
  position: absolute;
  border-radius: 100%;
  padding-left: 2.2vw;
  padding-right: 2.2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border: 2px solid white; 
  box-shadow: 1px 1px 1px gray;
  z-index: 150;
  margin-left: -32.3vw; 
  background-color: white;
  margin-top: 13vw;
  font-size: 2vw;
  font-weight: bolder;
  color: gray;
}
#active{
  border: 2px solid #E5007e; 
  box-shadow: 1px 1px 1px #E5007e;
  font-weight: bolder;
}
.numero2-busquedas{
  position: absolute;
  border-radius: 100%;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border: 2px solid white; 
  box-shadow:  1px 1px 1px gray;;
  z-index: 150;
  margin-left: -32.3vw; 
  background-color: white;
  margin-top: 23.5vw;
  font-size: 2vw;
  font-weight: bolder;
  color: gray;
  
}
.numero3-busquedas{
  position: absolute;
  border-radius: 100%;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border: 2px solid white; 
  box-shadow:  1px 1px 1px gray;;
  z-index: 150;
  margin-left: -32.3vw; 
  background-color: white;
  margin-top: 34vw;
  font-size: 2vw;
  font-weight: bolder;
  color: gray;
  
}
#negrita-busquedas{
  font-weight: bolder;
  font-size: 1.4vw;
}

.busqueda-busquedas{
  position: absolute;
  width: 10vw;
  margin-top: 14.5vw;
  z-index: 150; 
  margin-left: -43vw;
  color: white;
  font-weight: lighter;
  font-size: 1.2vw;
}
.perfilProfesional-busquedas{
  position: absolute;
  width: 12vw;
  margin-top: 25vw;
  z-index: 150; 
  margin-left: -46vw;
  color: white;
  font-weight: lighter;
  font-size: 1.2vw;
}
.requisitos-busquedas{
  position: absolute;
  width: 10vw;
  margin-top: 35.5vw;
  z-index: 150; 
  margin-left: -43vw;
  color: white;
  font-weight: lighter;
  font-size: 1.2vw;
}
/* BUSQUEDA2 */
.nombreBusqueda{
  position: absolute; 
  margin-top: 3vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-nombre{
  position: absolute;
  width: 20vw;
  height: 2.3vw;
  margin-top: 1.4vw;
  margin-left: 0vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.cargoBuscado{
  position: absolute; 
  margin-top: 3vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-busquedas{
  position: absolute;
  width: 20vw;
  height: 2.5vw;
  margin-top: 2.5vw;
  margin-left: -6vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.jornadaBuscada{
  position: absolute; 
  margin-top: 10vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-jornada{
  position: absolute;
  width: 20vw;
  height: 2.5vw;
  margin-top: 2.5vw;
  margin-left: -15vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.modalidadBuscada{
  position: absolute; 
  margin-top: 10vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-modalidad{
  position: absolute;
  width: 20vw;
  height: 2.5vw;
  margin-top: 2.5vw;
  margin-left: -5.3vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.areaBuscada{
  position: absolute; 
  margin-top: 16vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-area{
  position: absolute;
  width: 20vw;
  height: 2.5vw;
  margin-top: 2.5vw;
  margin-left: -16.9vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.regionBuscada{
  position: absolute; 
  margin-top: 16vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-region{
  position: absolute;
  width: 20vw;
  height: 2.5vw;
  margin-top: 2.5vw;
  margin-left: -8.5vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.horasRequeridas{
  position: absolute; 
  margin-top: 22.5vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-horas{
  position: absolute;
  width: 20vw;
  height: 2.3vw;
  margin-top: 1.4vw;
  margin-left: 0vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.trabajoFlexible{
  position: absolute; 
  margin-top: 22.5vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-trabajo{
  position: absolute;
  width: 20vw;
  height: 2.5vw;
  margin-top: 1.5vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.necesidadViaje{
  position: absolute; 
  margin-top: 22.5vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-viaje{
  position: absolute;
  width: 20vw;
  height: 2.5vw;
  margin-top: 2.5vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
/* BUSQUEDA3 */
.profesion-busquedas{
  position: absolute; 
  margin-top: 2vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  
}
.input-profesion{
  border-radius: 5px;
  width: 20vw;
  border: 1px solid #D9D9D9;
  margin-top: 2vw;   
  font-size: 1vw;
}
.ejemplo-profesion{
  font-size: 0.75vw;
  color:#5A5A5A;
  width: 20vw;
  position: absolute;
  margin-top: 0.4vw;
}
.anosBuscados{
  position: absolute; 
  margin-top: 2vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  
}
.input-rangoAnos{
  position: absolute;
  width: 20vw;
  height: 2.6vw;
  margin-top: 3vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
  margin-left: -14vw;
}
.areas-busquedas{
  position: absolute; 
  margin-top: 12vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
}
.input-areas{
  border-radius: 5px;
  width: 20vw;
  border: 1px solid #D9D9D9;
  margin-top: 5vw;
  font-size: 1vw;
}
.ejemplo-area{
  font-size: 0.75vw;
  color:#5A5A5A;
  width: 20vw;
  position: absolute;
  margin-top: 0.4vw;
}
.ejemplo-area1{
  font-size: 0.75vw;
  color:#5A5A5A;
  width: 20vw;
  position: absolute;
  margin-top: 1.5vw;
}
.industria-busquedas{
  position: absolute; 
  margin-top: 25vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  
}
.input-industria{
  border-radius: 5px;
  width: 20vw;
  border: 1px solid #D9D9D9;
  margin-top: 0.5vw;   
  font-size: 1vw;
}
.altaDireccion{
  position: absolute; 
  margin-top: 10vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-ad{
  position: absolute;
  width: 20vw;
  height: 2.6vw;
  margin-top: 1.5vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.experienciaDirectorios{
  position: absolute; 
  margin-top: 16vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-ed{
  position: absolute;
  width: 20vw;
  height: 2.6vw;
  margin-top: 2vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
/* BUSQUEDA4 */
.postgrado-busquedas{
  position: absolute; 
  margin-top: 2vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  
}
.input-postgrado{
  position: absolute;
  width: 20vw;
  height: 2.6vw;
  margin-top: 2vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.resultadoPerso{
  position: absolute; 
  margin-top: 2vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
  
}
.input-personalidad{
  position: absolute;
  width: 20vw;
  height: 2.6vw;
  margin-top: 2vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
  margin-left: -6vw;
}
.competencias-busquedas{
  position: absolute; 
  margin-top: 10vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  
}
.input-competencias{
  border-radius: 5px;
  width: 20vw;
  border: 1px solid #D9D9D9;
  margin-top: 2vw;   
  font-size: 1vw;
}
.idioma-busquedas{
  position: absolute; 
  margin-top: 10vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  
}
.input-idiomas{
  border-radius: 5px;
  width: 20vw;
  border: 1px solid #D9D9D9;
  margin-top: 2vw;   
  font-size: 1vw;
}
/* BUSQUEDA5 */