
/* INICIO */
.WT {
  text-align: center;
  font-family: 'Montserrat-Medium';
  z-index:1000;
}


.WT-logo {
  height: 100%;
  width: 100%;
  background-color: white;
  border-color: white;
  margin-top: -10;
  z-index:100;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #f8f9fa; /* Color claro y profesional */ /* Fondo para no superponer contenido */}
body {
  margin-top: 60px; /* Ajuste para evitar superposición con el contenido */
}
/* Ajustes para la top navbar */
.topnavbar {
  background-color: #F9F9F9;
  overflow: visible; /* Cambiamos overflow a visible */
  position: fixed;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
}

.topnavbar a {
  color: rgb(73, 73, 73);
  text-align: center;
  padding: 2% 2%;
  text-decoration: none;
  font-size: 1.2vw;
  font-weight: bold;
}

.topnavbar img {
  height: 9%;
  width: 9%;
  padding: 0.5% 4%;
}

.topnavbar a:hover {
  background-color: #E5007e;
  color: white;
}

.topnavbar a.active {
  background-color: #f9dbbd;
  color: white;
}

/* Ajustes para el submenú */
.submenu {
  position: relative;
}

.submenu-content {
  display: none;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  position: absolute;
  top: 100%; /* Cambiamos left a top */
  left: 0;
  width: 100%; /* Ancho del submenú igual al 100% */
}

.submenu-content {
  display: none;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  position: absolute;
  top: 100%; /* Cambiamos left a top */
  left: 0; /* Cambiamos right a left */
  width: 100%; /* Ancho del submenú ajustado */
}


.submenu-content a:hover {
  background-color: #ddd;
}

.submenu:hover .submenu-content {
  display: block; /* Cambiamos flex a block */
}

.wot1{
  z-index:10px; 
  margin-top: 10%;
  margin-left: -75%;
  position: absolute;
  height: 50%; 
  width: 50%;
}
.vlc6 {
  border-left: 4px solid white;
  height: 88vw;
  /* margin-bottom: -20%; */
  margin-left: 10vw;
  z-index: 12px;
  position: absolute;
  margin-top: -50.1vw;
   
}
.cuadrado-fondo{
  height: 87.5%;
  width: 100%;
  background-color: rgb(73, 73, 73) ;
  z-index: 5px;
  position:absolute;
  opacity: 0.7;
  margin-top: -50.1%;
}
.hashtag1{
  font-size: 170%;
  font-weight:900;
  color: white;
  margin-left: -85%; 
  position: absolute;
  margin-top: 45%;
}
.hashtag2{
  font-size: 170%;
  font-weight:900;
  color: white;
  margin-left: -12%; 
  position: absolute;
  margin-top: 45%;
}
/* NUESTRO PROPOSITO */
.mujeres3{
  margin-top: -5vw;
  color:#E5007e;
  font-size: 3vw;
  font-weight: bold;
  margin-right: 25vw;
  margin-bottom: 50vw;
}
.rosa{
  color:#E5007e;
}
.wot5{
  height: 5vw;
  width: 5vw;
  margin-top: 2vw;
  margin-right: 67vw;
}
.wot6{
  height: 35%;
  width: 35%;
  margin-top: 2%;
  margin-left: -34%;
  z-index: 5px;
}
.vlc {
  border-left: 4px solid #00b4cc;
  height: 101.2vw;
  /* margin-bottom: -20%; */
  margin-left: 10vw;
  z-index: 10px;
  position: absolute;
  margin-top: -0.2vw;
   
}
.circulo1{
  width: 10vw;
  text-align: center; 
  margin-top: 8vw;
  margin-left: -31vw;
  position: absolute;
  z-index: 10px;
  font-size: 1.2vw;

}
.circulo2{
width: 10vw;
text-align: center; 
margin-top: 8vw;
margin-left: -14vw;
position: absolute;
z-index: 10px; 
font-size: 1.2vw;
}

.circulo3{
margin-top: 20vw;
margin-left: -31vw;
position: absolute;
z-index: 10px; 
font-size: 1.2vw;
width: 30vw;
text-align: justify;
}
.GrupoPersonas{
  height: 45%;
  width: 45%;
  z-index: 1px;
  border-top-left-radius: 600px;
  border-top: 70px solid;
  border-left: 70px solid;
  border-color: #E5007e;
  margin-top: -31%;
  margin-left: 50%; 
  
}
.Nuestro-proposito{
  background-color: white;
  min-height: 100%;
}
/* PROYECTO */
.mujerCelular{
  height: 100%;
  width: 100%;
  z-index: 1px;
  margin-top: -4%;
}
.wot8{
  height: 5vw;
  width: 5vw;
  margin-top: -3vw;
  margin-left: -87vw;
  z-index:10px; 
  position: absolute;
}
.mujeres2{
  margin-top: -32vw;
  color:#E5007e;
  font-size: 3vw;
  font-weight: bold;
  margin-right: 40vw;
  margin-bottom: 50vw;
}
.vlc7 {
  border-left: 4px solid #00b4cc;
  height: 155.2vw;
  /* margin-bottom: -20%; */
  margin-left: 10vw;
  z-index: 10px;
  position: absolute;
  margin-top: -0.2vw;
   
}
.wot9{
  position: absolute;
  height:10vw; 
  width: 30vw;
  margin-left: 20vw;
  margin-top: -2vw;
}

.wot10{
  height:7.5vw; 
  width: 80vw;
  margin-right: -10vw;
  margin-top: 12vw;
}
.hashtag3{
  font-size: 2vw;
  font-weight:900;
  color: black;
  margin-left: 1vw; 
  position: absolute;
  margin-top: -22vw;
}
.hashtag4{
  font-size: 2vw;
  font-weight:900;
  color: black;
  margin-left: 1vw; 
  position: absolute;
  margin-top: -20vw;
}
/* COMO OPERA  */
.vlc2 {
  border-left: 4px solid #E5007e;
  height: 180vw;
  /* margin-bottom: -20%; */
  margin-left: 10vw; 
  z-index: 10px;
  position: absolute;
  margin-top: 3vw;
   
}
.Foto-mujeres{
  height: 100%;
  width: 100%;
  z-index: 1px;
  margin-top: 3%;
}
.mujeres_empresa{
  height: 100%;
  width: 100%;
  z-index: 1px;
  margin-top: -15%;
}
.wot11{
  height: 5vw;
  width: 5vw;
  margin-top: 3vw;
  margin-left: -85vw;
  z-index:10px; 
  position: absolute;
}
.wot11_1{
  height: 5vw;
  width: 5vw;
  margin-top: -12vw;
  margin-left: -86vw;
  z-index:10px; 
  position: absolute;
}
.mujeres1{
  margin-top: -41.5vw;
  color:#E5007e;
  font-size: 3vw;
  font-weight: bold;
  margin-left: -10vw;
  margin-bottom: 50vw;
}
.mujeres1_1{
  margin-top: -59vw;
  color:#00b4cc;
  font-size: 3vw;
  font-weight: bold;
  margin-left: -10vw;
  margin-bottom: 50vw;
}
.hashtag5{
  font-size: 170%;
  font-weight:900;
  color: black;
  margin-left: -35%; 
  position: absolute;
  margin-top: -17%;
  z-index: 90;
}
.mail1{
  position: absolute;
  z-index: 10px; 
  font-size: 1.5vw;
  text-align: justify;
  margin-top: -16.5vw;
  margin-left: -35vw;
  font-weight: bolder;
  color:#E5007e
 
}
/* POR QUE PREFERIRNOS */
.wot11_2{
  height: 5vw;
  width: 5vw;
  margin-top: -12vw;
  margin-left: -85vw;
  z-index:10px; 
  position: absolute;
}
.negrita{
  font-weight: bolder;
}
.vlc3 {
  border-left: 4px solid #00b4cc;
  height: 40vw;
  /* margin-bottom: -20%; */
  margin-left: 10vw;
  z-index: 10px;
  position: absolute;
  margin-top: -12vw;
}
.Rubia-foto {
  height: 100%;
  width: 100%;
  z-index: 1px;
  margin-top: -12%;

}
.wot13{
  height: 90%;
  width: 45%;
  margin-left: -44.3%;
  margin-top: -18%;
  z-index:10px; 
  position: absolute;
}
.preferirnos1{
  margin-top:-45vw ;
  color:#E5007e;
  font-size: 3vw;
  font-weight: bold;
  margin-right: 20vw;
  
}
/* QUIENES SOMOS */
.vlc4 {
  border-left: 4px solid #00b4cc;
  height: 70vw;
  margin-left: 10vw;
  z-index: 10px;
  position: absolute;
  margin-top: 0vw;
   
}
.quienesSomos{
  /* display: flex;
  flex-direction: column; */
  background-color: white;
  align-items: center;
  min-height: 100%;  
}
.mujeres5{
  color:#E5007e;
  font-size: 3vw;
  font-weight: bold;
  margin-left: -28vw;
  margin-top: -4.2vw;
}
.silvia{
  max-width: 20vmin;
  max-height: 20vmin;
  border-radius: 150px;
}
.cata{
  max-width: 20vmin;
  max-height: 20vmin;
  border-radius: 150px;
}
.carolina{
  max-width: 20vmin;
  max-height: 20vmin;
  border-radius: 150px;
}
.francisca{
  max-width: 20vmin;
  max-height: 20vmin;
  border-radius: 150px;
}
.hl {
  border-left: 6px solid #E5007e;
  width: 200px;
  height: 2px;
  background-color: #E5007e;
  color:#E5007e;
  border-color: #E5007e;
   
}
.wot4{
  height: 5%;
  width: 5%;
  margin-top: 6%;
  margin-left: -70%;
}
/* INSCRIPCION */
.wot14{
  
  height: 4vw;
  width: 4vw;
  margin-right: 70vw;
  margin-top:15vw;
}
.wot16{
  position:absolute;
  height: 20vw;
  width: 20vw;
  margin-left: 15vw;
  margin-top: -17vw;
  /* margin-bottom: 3%; */
  
}
.wot21{
  height: 5%;
  width: 5%;
  margin-left: -8%;
  margin-top:0.5%;
  position: absolute;
  color:grey;
}

.vlc3 {
  border-left: 4px solid #00b4cc;
  height: 97.2vw;
  /* margin-bottom: -20%; */
  margin-left: 10vw;
  z-index: 10px;
  position: absolute;
  margin-top: -12vw;
}
.vl {
  border-left: 4px solid #E5007e;
  height: 35vw;
  /* margin-bottom: -20%; */
  margin-left: 10vw;
  margin-top: -12vw; 
  z-index: 90;
  position: absolute;
}
.Foto1{
  position:absolute;
  z-index: 95;
  width: 50.2vw;
  height: 10vw;
  margin-left: -13vw;
  margin-top: 2vw;
 
}
.Foto4{
  position:absolute;
  z-index: 95;
  width: 50.2vw;
  height: 10vw;
  margin-left: -50vw;
  margin-top: 5.5vw;
 
}
.Foto2{
  position:absolute;
  margin-top: 2vw;
  width: 50.2vw;
  height: 10vw;
  margin-left: 37vw;
}
.Foto3{
  position:absolute;
  margin-top: 5.5vw;
  width: 50.5vw;
  height: 10vw;
  margin-left: 0vw;
}
.hashtag6{
  font-size: 1.7vw;
  font-weight:900;
  color: black;
  margin-left: -60vw; 
  position: absolute;
  margin-top: 1vw;
  z-index: 90;
}
#Button-buscar2{
  flex-direction: column;
    width: 8vw;  
    height: 0.3vw;
    padding-left: 5vw; /*con flecha 4 */
    padding-right: 5vw; /*con flecha 9 */ 
    padding-top: 1vw;
    padding-bottom: 2vw;
    margin-top: -3vw;
    margin-left: 16vw;
    border-radius: 15px;  
    text-align:center; /*con flecha left */
    border: 1px solid #E5007e;
    background:white; 
    color: gray;
    position: absolute;
    font-weight: bolder;
    /* bottom: -400px; 
    right: 550px; */
    font-size: 1vw;
    font-family: 'Montserrat-Medium';
    text-decoration: none;
    
  }
  #Button-buscar2:hover {
    background: #E5007e;
    transition: 0.5s;
    color:white;
  }
/* EXTRA */
.preferirnos2{
  margin-top:-50% ;
}
.vlc5 {
  border-left: 4px solid #00b4cc;
  height: 620vw;
  /* margin-bottom: -20%; */
  margin-left: 10vw;
  margin-top: 7vw; 
  z-index: 10px;
  position: absolute;
   
}
.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url(./Fonts/Montserrat-Medium.ttf) ;
}

@media screen and (max-width: 480px) {
  .topnavbar a{
    float: right;
    color: rgb(73, 73, 73);
    text-align: center;
    padding: 2vw 10vw;
    text-decoration: none;
    font-size: 2vw;
    font-weight:bolder;
  }
  .topnavbar img{
    float: left;
    height: 5vw;
    width: 5vw;
    padding: 1vw 4vw;
  }
  .WT-logo {
    height: 60vw;
    width: 100vw;
    background-color: white;
    border-color: white;
    z-index:50;
    
  }
  .vlc6 {
    border-left: 4px solid white;
    height: 50vw;
    /* margin-bottom: -20vw; */
    margin-left: 10vw;
    z-index: 12px;
    position: absolute;
    margin-top: -65vw;
     
  }
  .GrupoPersonas{
    display: none;
    
  }
  .mujerCelular{
    display: none;
  }
  .Foto-mujeres{
    display:none;
  }
  .mujeres_empresa{
    display:none;
  }
  .Rubia-foto {
    display:none; 
  }
  .Foto1{
    display: none;
  }
  .Foto2{
   display: none;
  }
  .Foto3{
    display:none; 
  }
  .Foto4{
    display:none;
  }
  .wot5{
    display:none;
  }
  .mujeres3{
    color:#E5007e;
    font-size: 5vw;
    font-weight: bold;
    margin-top: 3vw;
    margin-left: -7vw;
    width: 100vw;
  }
  .wot6{
    height: 60%;
    width: 60%;
    margin-top: 2%;
    margin-left: 0%;
    z-index: 5px;
  }
  .wot8{
    display: none;
  }
  .wot9{
    display: none;
  }
  .wot11{
    display: none;
  }
  .wot11_2{
    display: none;
  }
  .wot13{
    display: none;
  }
  .wot14{
    display: none;
  }
  .wot4{
    display: none;
  }
  .hashtag1{
    display: none;
  }
  .hashtag2{
    display: none;
  }
  .hashtag3{
    display: none;
  }
  .hashtag4{
    display: none;
  }
  .hashtag5{
    display: none;
  }
  .hashtag6{
    display: none;
  }
  .mujeres2{
    margin-top: 20vw;
    color:#E5007e;
    font-size: 5vw;
    font-weight: bold;
    margin-left: -3vw;
  }
  .circulo1{
    width: 20vw;
    text-align: center; 
    margin-top: 13vw;
    margin-left: -54vw;
    position: absolute;
    z-index: 10px;
    font-size: 2vw;

    
  }
  .circulo2{
  width: 20vw;
  text-align: center; 
  margin-top: 13vw;
  margin-left: -25vw;
  position: absolute;
  z-index: 10px; 
  font-size: 2vw;
  
  
  }
  .circulo3{
  margin-top: 35vw;
  margin-left: -60vw;
  position: absolute;
  z-index: 10px; 
  font-size: 120vw;
  width: 63vw;
  text-align: justify;
  font-size: 3vw;
  }
  .mujeres1{
    margin-top: 10vw;
    color:#E5007e;
    font-size: 5vw;
    font-weight: bold;
    margin-left: 4vw;
    width: 100vw;
  }
  .mujeres1_1{
    margin-top: -20vw;
    color:#E5007e;
    font-size: 5vw;
    font-weight: bold;
    margin-left: 6vw;
    width: 100vw;
  }
  .mail1{
    position: absolute;
    z-index: 10px; 
    font-size: 2vw;
    text-align: justify;
    margin-top: -18vw;
    margin-left: -35vw;
    font-weight: bolder;
    color:#E5007e
   
  }
  .wot10{
    height:80%; 
    width: 80%;
    margin-left: -5vw;
    margin-top: 5%;
  }
  .preferirnos1{
    margin-top:-10vw ;
    color:#E5007e;
    font-size: 5vw;
    font-weight: bold;
    margin-left:-2vw;
    width: 100vw;
    
  }
  .mujeres5{
    color:#E5007e;
    font-size: 5vw;
    font-weight: bold;
    margin-left: -12vw;
    margin-top: 9vw;
    width: 100vw;
  }
  .wot16{
    position: absolute;
    height: 80vw;
    width: 80vw;
    margin-left: -30vw;
    margin-top:10vw;
    /* margin-bottom: 3%; */
    
  }
  #Button-buscar2{
    flex-direction: column;
      width: 50vw;  
      height: 5vw;
      padding-left: 5vw; /*con flecha 4 */
      padding-right: 5vw; /*con flecha 9 */ 
      padding-top: 2vw;
      padding-bottom: 2vw;
      margin-top: 70vw;
      margin-left: -20vw;
      border-radius: 15px;  
      text-align:center; /*con flecha left */
      border: 1px solid #E5007e;
      background:white; 
      color: gray;
      position: absolute;
      font-weight: bolder;
      /* bottom: -400px; 
      right: 550px; */
      font-size: 3vw;
      font-family: 'Montserrat-Medium';
      text-decoration: none;
      
    }
    .vlc {
      border-left: 4px solid #00b4cc;
      height: 200vw;
      margin-left: 10vw;
      z-index: 10px;
      position: absolute;
      margin-top: 0vw;
       
    }
    .vlc7 {
      position:absolute;
      height: 200vw;
      margin-top: -78vw;
      z-index: 30;
       
    }
    .vl {
      border-left: 4px solid #00b4cc;
      height: 200vw;
      margin-top: -100vw;
    
      
    }
    .vlc2{
      height: 200vw;
      border-left: 4px solid #00b4cc;
    }
    .vlc3{
      height: 200vw;
    }
    .vlc4{margin-top: 110vw;
      height: 200vw;
    }
    .no{
      display: none;
    }
    .wot11_1{
      display: none;
    }
}
/* @media screen and (max-width: 380px) {
  .vlc4{
    height: 300%;
  }
  .mujeres3{
    color:#E5007e;
    font-size: 130%;
    font-weight: bold;
    margin-top: 3%;
    margin-left: -3%;
    width: 100%;
  }
  .vl {
    border-left: 4px solid #00b4cc;
    height: 80%;
    margin-top:-10%
  }
  .vlc {
      border-left: 4px solid #00b4cc;
      height: 200%;
      margin-left: 10%;
      z-index: 10px;
      position: absolute;
      margin-top: -4%;
       
    }
  .vlc7 {
      display: none;
       
    }
  .vlc2{
      height: 200%;
      border-left: 4px solid #00b4cc;
    }
  .vlc3{
      height: 200%;
    }
  .preferirnos1{
      margin-top:0% ;
      color:#E5007e;
      font-size: 130%;
      font-weight: bold;
      margin-left:1%;
      width: 100%;
      
    }
  .mujeres5{
      color:#E5007e;
      font-size: 130%;
      font-weight: bold;
      margin-left: -9%;
      margin-top: 9%;
      width: 100%;
    }  
  .mujeres1{
      margin-top: 10%;
      color:#E5007e;
      font-size: 130%;
      font-weight: bold;
      margin-left: 12%;
      width: 80%;
      text-align: left;
    }
  .mujeres1_1{
      margin-top: -20%;
      color:#E5007e;
      font-size: 130%;
      font-weight: bold;
      margin-left: 12%;
      width: 80%;
      text-align: left;
    }
  .mujeres2{
      margin-top: 20%;
      color:#E5007e;
      font-size: 130%;
      font-weight: bold;
      margin-left: 9%;
    }
  
} */
@media screen and (min-width: 500px) and (max-width: 1024px)  {
  /* navbar */
  .topnavbar a{
    float: right;
    color: rgb(73, 73, 73);
    text-align: center;
    padding: 2% 10%;
    text-decoration: none;
    font-size: 120%;
    font-weight:bolder;
  }
  .topnavbar img{
    float: left;
    height: 9%;
    width: 9%;
    padding: 1% 4%;
  }
  .no{
    display: none;
  }
  /* inicio */
  .WT-logo {
    height: 105%;
    width: 105%;
    background-color: white;
    border-color: white;
    margin-top: -10;
    z-index:1px;
    
  }
  /* nuestro proposito */
  .GrupoPersonas{
    height: 45%;
    width: 45%;
    z-index: 1px;
    border-top-left-radius: 600px;
    border-top: 70px solid;
    border-left: 70px solid;
    border-color: #E5007e;
    margin-top: -31%;
    margin-left: 51.4%; 
    
  }

  /* proyecto */
  .mujerCelular{
    height: 120%;
    width: 105%;
    z-index: 1;
    margin-top: -1%;
    margin-left: 0%;
  }
  .wot9{
    display:none; 
  }
  /* como opera */
  .Foto-mujeres{
    height: 110%;
    width: 105%;
    z-index: 1px;
    margin-top: 3%;
  }
  .mujeres_empresa{
    height: 105%;
    width: 105%;
    z-index: 1px;
    margin-top: -17.7%;
  }
  .hashtag5{
    font-size: 170%;
    font-weight:900;
    color: black;
    margin-left: -35%; 
    position: absolute;
    margin-top: -21%;
    z-index: 90;
  }

  .Rubia-foto {
    height: 105%;
    width: 105%;
    z-index: 1px;
    margin-top: -13%;
  
  }
  .wot13{
    display:none;
  }
  /* quienes somos */
  .vlc4 {
    border-left: 4px solid #00b4cc;
    height: 135vw;
    margin-left: 10vw;
    z-index: 10px;
    position: absolute;
    margin-top: 0vw;
     
  }
  .wot4{
    height: 6%;
    width: 6%;
    margin-top: 10%;
    margin-left: -70%;
  }
  /* inscripcion */
  .wot14{
    display: none;
  }
  .wot21{
    height: 6%;
    width: 6%;
    margin-left: -8%;
    margin-top:0.5%;
    position: absolute;
    color:grey;
  }
  .wot16{
    position:absolute;
    height: 30vw;
    width: 30vw;
    margin-left: -15vw;
    margin-top: 5vw;
    /* margin-bottom: 3%; */
    
  }
  .vl {
    border-left: 4px solid #E5007e;
    height: 50vw;
    /* margin-bottom: -20%; */
    margin-left: 10vw;
    margin-top: -2vw; 
    z-index: 90;
    position: absolute;
  }
  #Button-buscar2{
    flex-direction: column;
      width: 17vw;  
      height: 1.5vw;
      padding-left: 5vw; /*con flecha 4 */
      padding-right: 5vw; /*con flecha 9 */ 
      padding-top: 1vw;
      padding-bottom: 2vw;
      margin-top: 27vw;
      margin-left: -13.5vw;
      border-radius: 15px;  
      text-align:center; /*con flecha left */
      border: 1px solid #E5007e;
      background:white; 
      color: gray;
      position: absolute;
      font-weight: bolder;
      /* bottom: -400px; 
      right: 550px; */
      font-size: 1.5vw;
      font-family: 'Montserrat-Medium';
      text-decoration: none;
      
    }
  .Foto1{
    display:none;

   
  }
  .Foto2{
    display:none;
  }

  .Foto3{
    display:none;
  }
  .Foto4{
    display:none;
  }
  
}
