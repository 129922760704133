/* Cosas comunes  */

/* Estilos comunes */
.commonContainer {
  background-color: #fff;
  border-bottom: 1px solid #D9D9D9;
  padding: 0.2vw;
  margin-top: 0vw;
  text-align: left;
}

.commonContainer p {
  margin-left: 1vw;
  margin-top: 0vw;
  margin-bottom: 0.5vw;
  font-size: 0.7vw;
}

.commonContainer h {
  margin-left: 1vw;
  margin-top: 0vw;
  font-size: 1vw;
  color: #5A5A5A;
}

.verDetalleBtn {
  /* Estilos comunes para el botón "Ver Detalle" */
  /* Ajusta según sea necesario */
  display: inline-block;
  padding: 0.5vw 1vw;
  font-size: 0.8vw;
  text-decoration: none;
  color: #5A5A5A;
  border: 1px solid #D9D9D9;
  border-radius: 30px;
  margin-top: -2vw;
  margin-left: 1vw;
}

.verDetalleBtn:hover {
  background: #50B1C9;
  color: white;
  transition: 0.5s;
}

.topnavbarempresas{
  background-color: #F9F9F9;
  overflow: hidden;
  position:sticky; 
  z-index: 1000;
  width: 100vw;
}
.topnavbarempresas a{
  float: right;
  color: rgb(73, 73, 73);
  text-align: center;
  padding: 2% 2%;
  text-decoration: none;
  font-size: 1.2vw;
  font-weight:bolder;
} 
.topnavbarempresas img{
  float: left;
  height: 4vw;
  width: 7vw;
  margin-left: 0vw;
  padding: 0.5vw 4vw;
}

.topnavbarempresas a:hover {
  background-color: #E5007e;
  color: white;
}
.topnavbarempresas a.active {
  background-color: #f9dbbd;
  color: white;
}

.WT1 {
  text-align: center;
  font-family: 'Montserrat-Medium';
}
/*  INICIO DE SESION  */

.rectangulo{
  position: absolute;
  width: 30vw;
  height: 52vw;
  background: #5A5A5A;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  z-index: 0; 
}

.containerempresas{
  border: 1px solid gray;
  box-shadow: 1px 1px 3px gray;
  border-radius: 10px;
  flex-direction: column;
  margin-top: 8vw;
  margin-left: 20vw; 
  padding: 1vw;
  width: 60vw;
  height: 35vw;
  z-index: 100;
  position: absolute;
  background-color: white;
  overflow: auto;
}
.textoLogin{
  position: absolute; 
  color: #50B1C9;
  font-size: 2vw;
  font-weight: bold;
  margin-top: 2vw;
  margin-left: 21.75vw;
}
.textoLogin-admin{
  position: absolute; 
  color: #50B1C9;
  font-size: 2vw;
  font-weight: bold;
  margin-top: 2vw;
  margin-left: 18.5vw;
}
.texto-admin{
  position: absolute; 
  color: #50B1C9;
  font-size: 2vw;
  font-weight: bold;
  margin-top: 2vw;
  margin-left: 10vw;
}
.nombreEmpresa{
  position: absolute; 
  margin-top: 7vw; 
  margin-left: 20vw;
  text-align: left;
  font-size: 1vw;
}
.nuevaContrasena{
  position: absolute; 
  margin-top: 12vw; 
  margin-left: 30vw;
  text-align: left;
  font-size: 1vw;
}
.contrasena{
  position: absolute; 
  margin-top: 13vw; 
  margin-left: 20vw;
  text-align: left;
  font-size: 1vw;
}
.nombreUsuario{
  position: absolute; 
  margin-top: 12vw; 
  margin-left: 5vw;
  text-align: left;
  font-size: 1vw;
}
.emailUsuario{
  position: absolute; 
  margin-top: 18.5vw; 
  margin-left: 5vw;
  text-align: left;
  font-size: 1vw;
}
.cargoUsuario{
  position: absolute; 
  margin-top: 7vw; 
  margin-left: 30vw;
  text-align: left;
  font-size: 1vw;
}
.contrasenaUsuario{
  position: absolute; 
  margin-top: 13vw; 
  margin-left: 30vw;
  text-align: left;
  font-size: 1vw;
}
.inputSelect {
  position: absolute;
  border-radius: 5px;
  width: 20vw;
  padding-top: 0.8vw;
  padding-bottom: 0.8vw;
  border: 1px solid #D9D9D9;
  margin-top: 7vw;
  margin-left: -25vw;
}

.input1 {
  font-size: 1vw; 
}
.inputEliminar {
    border-radius: 5px;
    width: 20vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
    border: 1px solid #D9D9D9;
    margin-top: 0.5vw;
    margin-left: 3vw;
}

.olvidaste{
  position: absolute; 
  margin-top: 25vw; 
  margin-left: 24.5vw;
  text-align: center;
  font-size: 0.85vw;
  color: #5A5A5A;
}

.registrate{
  position: absolute; 
  margin-top: 9vw; 
  margin-left: 1vw;
  text-align: center;
  font-size: 0.85vw;
  color: #5A5A5A;
}

.error-message {
  color: red;
  background-color: #ffe6e6;
  padding: 10px;
  margin-top: 1vw;
  border-radius: 10px;
  border: 1px solid red;
  font-size: 1vw;
}

.wot24{
  position: absolute;
  width: 55vw;
  height: 52vw;
  z-index: 0;
  margin-left: -5vw;
}
#login{
  width: 10vw;
  height: 3vw;
  font-size: 1.5vw;
  background-color: #E5007e;
  border-radius: 10px;
  padding-left: 2vw; 
  padding-right: 2vw;  
  /* padding-top: 1vw;
  padding-bottom: 1vw; */
  color: white;
  text-decoration: none;
  margin-top: 20vw;
  position: absolute;
  margin-left: -5vw;
  text-align: center;
}
#login:hover {
  background: #50B1C9;
  transition: 0.5s;
  color:white;
}
.boton-agregar-usuario{
  width: 13vw;
  height: 2vw;
  font-size: 1vw;
  background-color: #E5007e;
  border-radius: 10px;
  padding-left: 2vw; 
  padding-right: 2vw;  
  color: white;
  text-decoration: none;
  margin-top: -10vw;
  position: absolute;
  margin-left: 10vw;
  text-align: center;
}
.boton-agregar-usuario:hover{
  background: #50B1C9;
  transition: 0.5s;
  color:white;
}
#enviar{
  width: 13vw;
  height: 2vw;
  font-size: 1vw;
  background-color: #E5007e;
  border-radius: 10px;
  padding-left: 2vw; 
  padding-right: 2vw;  
  /* padding-top: 1vw;
  padding-bottom: 1vw; */
  color: white;
  text-decoration: none;
  margin-top: 15vw;
  position: absolute;
  margin-left: 10vw;
  text-align: center;
}
#enviar:hover {
  background: #50B1C9;
  transition: 0.5s;
  color:white;
}
#cambioContrasena{
  width: 13vw;
  height: 2vw;
  font-size: 1vw;
  background-color: #E5007e;
  border-radius: 10px;
  padding-left: 2vw; 
  padding-right: 2vw;  
  /* padding-top: 1vw;
  padding-bottom: 1vw; */
  color: white;
  text-decoration: none;
  margin-top: 20vw;
  position: absolute;
  margin-left: 10vw;
  text-align: center;
}
#cambioContrasena:hover {
  background: #50B1C9;
  transition: 0.5s;
  color:white;
}
#enviarAgregar{
  width: 13vw;
  height: 2vw;
  font-size: 1vw;
  background-color: #E5007e;
  border-radius: 10px;
  padding-left: 2vw; 
  padding-right: 2vw;  
  /* padding-top: 1vw;
  padding-bottom: 1vw; */
  color: white;
  text-decoration: none;
  margin-top: 5vw;
  position: absolute;
  margin-left: 10vw;
  text-align: center;
}
#enviarAgregar:hover {
  background: #50B1C9;
  transition: 0.5s;
  color:white;
}

/* Mi Perfil y Detalle Búsqueda*/
.containerMiPerfil1{
  border:1px solid gray;
  box-shadow: 1px 1px 3px gray;
  border-radius: 10px;
  flex-direction: column;
  margin-top: 4vw;
  margin-left: 34vw; 
  padding: 1vw;
  width: 61vw;
  height: 10vw;
  z-index: 100;
  position: absolute;
  background-color: white;
  font-size: 1vw;
  color: #323232;
}

.containerMiPerfil2{
  border: 1px outset gray;
  box-shadow: 1px 1px 3px gray;
  border-radius: 10px;
  flex-direction: column;
  margin-top: 19vw;
  margin-left: 34vw; 
  padding: 1vw;
  width: 61vw;
  height: 36vw;
  z-index: 100;
  position: absolute;
  background-color: white;
  overflow: auto;
  font-size: 1vw;
}
.containerMiPerfilMatch{
  border: 1px outset gray;
  box-shadow: 1px 1px 3px gray;
  border-radius: 10px;
  flex-direction: column;
  margin-top: 7vw;
  margin-left: 34vw; 
  padding: 1vw;
  width: 61vw;
  height: 30vw;
  z-index: 100;
  position: absolute;
  background-color: white;
  overflow: auto;
  font-size: 1vw;
}

/* Estilo para los botones */
.buttonContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 1vw;
}

.buttonContainer button {
  font-size: 1vw;
  padding: 0.5vw 1vw;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.buttonContainer button:hover {
  background-color: #E5007e;
  color: white;
}

.containerReversed2 {
  margin-top: 4vw;
}

.containerReversed1 {
  margin-top: 28vw;

}

.containerCentered2 {
  margin-left: 20vw;
  margin-top: 18vw;
  height: 21vw;
  
}

.containerCentered1 {
  margin-left: 20vw;
  margin-top: 5vw;
  
}

.subcontainerMiPerfil {
  background-color: #fff;
  border-bottom: 1px solid #D9D9D9;
  padding: 0.2vw;
  margin-top: 0vw;
  text-align: left;
}

.subcontainerMiPerfil p{
  margin-left: 1vw;
  margin-top: 0vw;
  margin-bottom: 0.5vw;
  font-size: 0.7vw;
}

.subcontainerMiPerfil h{
  margin-left: 1vw;
  margin-top: 0vw;
  font-size: 1vw;
  color:#5A5A5A;
}

.subcontainerMiPerfil h1{
  font-size: 1.3vw;
  font-style: bold;
  color:#E5007e;
  margin-top: -2.15vw;
  position: absolute;
  margin-left: 43vw;
  text-align: right;
}


#busqueda{
  width: 3vw;
  height: 0.8vw;
  font-size: 1vw;
  background-color: #E5007e;
  border-radius: 5px;
  padding-left: 3vw; 
  padding-right: 5vw; 
  padding-top: 1vw;
  padding-bottom: 1.5vw;
  color: white;
  text-decoration: none;
  margin-top: -10vh;
  position: absolute;
  margin-left: -25vw;
  text-align: center;
}
#busqueda:hover {
  background: #50B1C9;
  transition: 0.5s;
  color:white;
}
#busqueda.disabled {
  background-color: grey;
  color: white;
  pointer-events: none;
}

#detalle{
  width: 5vw;
  height: 0.2vw;
  font-size: 0.8vw;
  border-radius: 30px;
  padding-left: 2vw; 
  padding-right: 2vw; 
  padding-top: 0vw;
  padding-bottom: 1vw;
  color: #5A5A5A;
  border: 1px solid #D9D9D9;
  text-decoration: none;
  margin-top: -2vw;
  position: absolute;
  margin-left: 48vw;
  text-align: right;
}
#detalle:hover {
  background: #50B1C9;
  transition: 0.5s;
  color:white;
}

.boldMiPerfil{
  color: #323232;
  font-weight: bold;
  font-size: 1.5vw;
  margin-left: 1vw;
  margin-top: 1vw;
  text-align: left;
}

.normalMiPerfil{
  color: #5A5A5A;
  font-size: 0.8vw;
  margin-left: 1vw;
  margin-top: -1vw;
  text-align: left;
}
.normalEliminar{
  color: #5A5A5A;
  font-size: 1vw;
  margin-left: -10vw;
  margin-top: -1vw;
  text-align: left;
}
.normalPerfil{
  color: #5A5A5A;
  font-size: 1.1vw;
  margin-left: 7vw;
  margin-top: -1vw;
  text-align: left;
}

.rectanguloEmpresas{
  position: absolute;
  margin-top: 4vw;
  width: 30vw;
  height: 38vw;
  background: #D9D9D9;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  z-index: 0;
}

.rectanguloEmpresas p {
  margin-top: 2vw;
  margin-left: 2vw;
}

.rectanguloEmpresas a#lista {
  display: block; /* Asegura que sea un bloque completo */
  width: 90%; /* Ocupa todo el ancho del contenedor */
  padding: 10px 15px; /* Espaciado interno */
  margin-bottom: 10px; /* Espaciado entre botones */
  text-align: center; /* Centrar texto */
  background-color: #D9D9D9; /* Color de fondo del botón */
  color: #fff; /* Color del texto */
  text-decoration: none; /* Sin subrayado */
  border-radius: 5px; /* Bordes redondeados */
  font-size: 20px;
  font-weight: bold;
  transition: background-color 0.3s; /* Efecto suave al pasar el mouse */
}

.rectanguloEmpresas a#lista:hover {
  background-color: #E5007e; /* Color al pasar el mouse */
  cursor: pointer; /* Cambia a puntero */
}

.rectanguloEmpresas a#active {
  display: block; /* Asegura que sea un bloque completo */
  width: 90%; /* Ocupa todo el ancho del contenedor */
  padding: 10px 15px; /* Espaciado interno */
  margin-bottom: 10px; /* Espaciado entre botones */
  text-align: center; /* Centrar texto */
  background-color: #E5007e; /* Color de fondo del botón */
  color: #fff; /* Color del texto */
  text-decoration: none; /* Sin subrayado */
  border-radius: 5px; /* Bordes redondeados */
  font-size: 20px;
  font-weight: bold;
  transition: background-color 0.3s; 
}
.wot24Empresas{
  position: absolute;
  width: 46vw;
  height: 38vw;
  z-index: 0;
  margin-left: 5vw;
  margin-top:4vw;
}


/*  MATCH  */

.containerMatch2{  
  border: 1px outset gray;
  box-shadow: 1px 1px 3px gray;
  border-radius: 50px;
  flex-direction: column;
  margin-top: 30vw;
  margin-left: 35vw; 
  padding: 1vw;
  width: 55vw;
  height: 8.5vw;
  z-index: 100;
  position: absolute;
  background-color: white;
  font-size: 1vw;
}
.containerMatch1{
  border:1px solid gray;
  box-shadow: 1px 1px 3px gray;
  border-radius: 20px;
  flex-direction: column;
  margin-top: 7vw;
  margin-left: 35vw; 
  padding: 1vw;
  width: 55vw;
  height: 30vw;
  z-index: 100;
  position: absolute;
  background-color: white;
  font-size: 1vw;
  color: #323232;
  overflow: auto;
  
}

.containerMatch1 p{
  margin-left: 18vw;
  margin-top: 4vw;
  margin-bottom: 2vw;
  font-size: 1vw;
}

.containerMatch1 h1{
  font-size: 1.5vw;
  font-style: bold;
  color:#E5007e;
  margin-top: -3.2vw;
  position: absolute;
  margin-left: 45vw;
  text-align: right;
}

.subcontainerMatchimg{
    position: absolute;
    width: 11.5vw;
    height: 11.5vw;
    z-index: 0;
    margin-left: -2vw;
    margin-top: -1vw;
  }

.subcontainerMatch {
  background-color: #fff;
  border-bottom: 1px solid #D9D9D9;
  padding: 0.2vw;
  margin-top: 0vw;
  text-align: left;
  margin-left: 17vw;
}
.subcontainerEliminar {
  background-color: #fff;
  padding: 0.2vw;
  margin-top: 0vw;
  text-align: left;
  margin-left: 17vw;
}
.subcontainerAgregar {
  background-color: #fff;
  padding: 0.2vw;
  margin-top: 0vw;
  text-align: left;
  margin-left: 17vw;

}


.subcontainerMatch p{
  margin-left: 1vw;
  margin-top: 0vw;
  margin-bottom: 0.5vw;
  font-size: 0.7vw;
}

.subcontainerMatch h{
  margin-left: 1vw;
  margin-top: 0vw;
  font-size: 1vw;
  color:#5A5A5A;
}

.subcontainerMatch h1{
  font-size: 1.3vw;
  font-style: bold;
  color:#E5007e;
  margin-top: -2.15vw;
  position: absolute;
  margin-left: 43vw;
  text-align: right;
}
.subcontainerUsuario {
  background-color: #fff;
  border-bottom: 1px solid #D9D9D9;
  padding: 0.2vw;
  margin-top: 0vw;
  text-align: left;
  margin-left: 0vw;
}

.subcontainerUsuario p{
  margin-left: 1vw;
  margin-top: 0vw;
  margin-bottom: 0.5vw;
  font-size: 0.7vw;
}

.subcontainerUsuario h{
  margin-left: 1vw;
  margin-top: 0vw;
  font-size: 1vw;
  color:#5A5A5A;
}

.subcontainerUsuario h1{
  font-size: 1.3vw;
  font-style: bold;
  color:#E5007e;
  margin-top: -2.15vw;
  position: absolute;
  margin-left: 43vw;
  text-align: right;
}

.boton-usuario{
  position:absolute; 
  margin-top: -3vw;
  margin-left: 40vw;
  width: 10vw;
  height: 2vw;
  border-radius: 0.5vw;
  background-color: #50B1C9;
  border: 1px solid #C4C4C4;
}

.boton-usuario:hover{
  background-color: #E5007e;
}

.boton-bus{
  position:absolute; 
  margin-top: -2vw;
  margin-left: 40vw;
  width: 10vw;
  height: 2vw;
  border-radius: 0.5vw;
  background-color: #50B1C9;
  border: 1px solid #C4C4C4;
}

.boton-bus:hover{
  background-color: #E5007e;
}
.inputUsuaria{
  border-radius: 5px;
  width: 20vw;
  padding-top: 0.8vw;
  padding-bottom: 0.8vw;
  border: 1px solid #D9D9D9;
  margin-top: 0.5vw;
  margin-left: 30vw;
}


@media screen and (max-width: 1024px) and (orientation: portrait){

  /*  COMUNES  */
  .topnavbarempresas img{
    height: 9vw;
    width: 16vw;
}
  /*  INICIO DE SESION  */
  .rectangulo{
    display: none;
}
  .wot24{
    display: none;
  }

  .containerempresas{
    width: 80vw;
    height: 100vw;
    margin-top: 10vw;
    margin-left: 9vw;
  }
  .textoLogin{
    position: absolute; 
    color: #50B1C9;
    font-size: 5vw;
    font-weight: bold;
    margin-top: 2vw;
    margin-left: 20vw;
  }
  
  .nombreEmpresa{
    margin-top: 22vw; 
    margin-left: 15vw;
    font-size: 2.5vw;
  }
  
  .contrasena{
    position: absolute; 
    margin-top: 36vw; 
    margin-left: 15vw;
    font-size: 2.5vw;
  }

    
  .olvidaste{
    margin-top: 100vw; 
    margin-left: 12vw;
    font-size: 2vw;
  }

  #login{
    width: 8vw;
    height: 4.5vw;
    font-size: 3vw;
    margin-top: 52vw;
    position: absolute;
    margin-left: -8vw;
    text-align: center;
  } 

  /*  MI PERFIL Y DETALLE BUSQUEDA  */

  .containerMiPerfil1{
    margin-top: 4vw;
    margin-left: 48vw; 
    width: 43vw;
    height: 38vw;
  }
  
  .containerMiPerfil2{
    margin-top: 50vw;
    margin-left: 10vw; 
    width: 80vw;
    height: 50vw;
    font-size: 1vw;
  }
  .containerMiPerfilMatch{
    margin-top: 50vw;
    margin-left: 10vw; 
    width: 80vw;
    height: 50vw;
    font-size: 1vw;
  }
  
  .containerReversed2 {
    margin-top: 50vw;
  }
  
  .containerReversed1 {
    margin-top: 4vw;
  }
  
  .subcontainerMiPerfil p{
    margin-left: 1vw;
    margin-top: 0vw;
    margin-bottom: 0.5vw;
    font-size: 1.8vw;
  }
  
  .subcontainerMiPerfil h{
    margin-left: 1vw;
    margin-top: 0vw;
    font-size: 2vw;
    color:#5A5A5A;
  }
  
  .subcontainerMiPerfil h1{
    font-size: 2vw;
    margin-top: -2.15vw;
    margin-left: 43vw;
  }
  
  
  #busqueda{
    width: 8vw;
    height: 4vw;
    font-size: 2vw;
    margin-top: 10vw;
    margin-left: -9vw;
  }
  
  #detalle{
    width: 8vw;
    height: 0.5vw;
    font-size: 1.4vw;
    margin-top: -3vw;
    margin-left: 60vw;
  }
  
  .boldMiPerfil{
    color: #323232;
    font-weight: bold;
    font-size: 2.5vw;
    margin-left: 1vw;
    margin-top: 1vw;
    text-align: left;
  }
  
  .normalMiPerfil{
    color: #5A5A5A;
    font-size: 2.2vw;
    margin-left: 1vw;
    margin-top: -1vw;
    text-align: left;
  }
  
  .rectanguloEmpresas{
    margin-top: 4vw;
    margin-left:10vw;
    width: 35vw;
    height: 40vw;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
  }
  
  .rectanguloEmpresas p {
    margin-top: 2vw;
    margin-left: 2vw;
  }
  
  .wot24Empresas{
    display: none;
  }
}
